<template>
	<el-dialog :title="!dataForm.pipingId ? '新增管系进度跟踪' : '修改管系进度跟踪'" :close-on-click-modal="false" :visible.sync="visible"
		width="650px">
		<el-form ref="dataForm" :model="dataForm" :rules="dataRule" inline label-width="140px" label-position="left">
			<el-row>
				<el-form-item label="标的物" prop="subjectNo">
					<el-select class="selItemInput" v-model="dataForm.subjectNo" placeholder="请选择" @change="handleChange"
						:disabled="updateSortFlg">
						<el-option v-for="item in $store.state.common.navBarSubjectList" :key="item.displayValue"
							:label="item.displayName" :value="item.displayValue">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="序号" prop="sort">
					<el-input-number v-model="dataForm.sort" controls-position="right" :min="1"
						disabled="true"></el-input-number>
				</el-form-item>
				<el-form-item label="项目名称" prop="pipingProjectName">
					<el-input class="selItemInput" type="input" v-model="dataForm.pipingProjectName" placeholder="请填写">
					</el-input>
				</el-form-item>
				<el-form-item label="强度试验完成时间">
					<el-date-picker class="selItemInput" value-format="yyyy-MM-dd" v-model="dataForm.testFinishTime"
						type="date" style="width:300px" :disabled="unuseTestFinishTimeFlg">
					</el-date-picker>
					<el-label style="padding:0 10px;">使用</el-label>
					<el-switch v-model="unuseTestFinishTimeFlg" active-color="#cdcdce" inactive-color="#7dbae2"></el-switch>
				</el-form-item>
				<el-form-item label="密性完成时间">
					<el-date-picker class="selItemInput" value-format="yyyy-MM-dd" v-model="dataForm.densityFinishTime"
						type="date" style="width:300px" :disabled="unuseDensityFinishTimeFlg">
					</el-date-picker>
					<el-label style="padding:0 10px;">使用</el-label>
					<el-switch v-model="unuseDensityFinishTimeFlg" active-color="#cdcdce" inactive-color="#7dbae2"></el-switch>
				</el-form-item>
				<el-form-item label="串油清洁完成时间">
					<el-date-picker class="selItemInput" value-format="yyyy-MM-dd" v-model="dataForm.cleanFinishTime"
						type="date" style="width:300px" :disabled="unuseCleanFinishTimeFlg">
					</el-date-picker>
					<el-label style="padding:0 10px;">使用</el-label>
					<el-switch v-model="unuseCleanFinishTimeFlg" active-color="#cdcdce" inactive-color="#7dbae2"></el-switch>
				</el-form-item>
				<el-form-item label="畅通试验时间">
					<el-date-picker class="selItemInput" value-format="yyyy-MM-dd" v-model="dataForm.unblockedTestTime"
						type="date" style="width:300px" :disabled="unuseUnblockedTestTimeFlg">
					</el-date-picker>
					<el-label style="padding:0 10px;">使用</el-label>
					<el-switch v-model="unuseUnblockedTestTimeFlg" active-color="#cdcdce" inactive-color="#7dbae2"></el-switch>
				</el-form-item>
				<el-form-item label="备注">
					<el-input class="width391" type="input" v-model="dataForm.remark" placeholder="请填写">
					</el-input>
				</el-form-item>
			</el-row>
			<el-row class="formBtnGroup">
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain
					@click="visible = false">取消</el-button>
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
			</el-row>
		</el-form>
	</el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
	name: "purchaseDevice-add-or-update",
	data() {
		return {
			visible: true,
			unuseTestFinishTimeFlg: false,
			unuseDensityFinishTimeFlg: false,
			unuseCleanFinishTimeFlg: false,
			unuseUnblockedTestTimeFlg: false,
			updateSortFlg: false,
			form: {},
			dataForm: {
				workNo: $common.getItem("workNo"),
				subjectNo: '',
				pipingId: '',
				pipingProjectName: '',
				testFinishTime: "",
				unuseTestFinishTime: '',
				densityFinishTime: '',
				unuseDensityFinishTime: '',
				cleanFinishTime: '',
				unuseCleanFinishTime: '',
				unblockedTestTime: '',
				unuseUnblockedTestTime: '',
				remark: '',
				sort: 1
			},
			dataRule: {
				subjectNo: [
					{ required: true, message: "标的物不能为空", trigger: "change" }
				],
				sort: [
					{ required: true, message: "序号不能为空", trigger: "change" }
				],
				pipingProjectName: [
					{ required: true, message: "项目名称不能为空", trigger: "blur" }
				]
			},
			contractSigns: [{
				value: '0',
				label: '未签订'
			}, {
				value: '1',
				label: '已签订'
			}],
		};
	},
	components: {

	},
	// mounted() {
	// 	this.getSubjectDropDown();
	// },
	methods: {
		getCurrentTime() {
			//获取当前时间并打印
			var _this = this;
			let yy = new Date().getFullYear();
			let mm = new Date().getMonth() + 1;
			let dd = new Date().getDate();
			let hh = new Date().getHours();
			let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
			let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
			return yy + '-' + mm + '-' + dd;
		},
		init(id) {
			this.dataForm = {
				workNo: $common.getItem("workNo"),
				subjectNo: '',
				pipingId: '',
				pipingProjectName: '',
				testFinishTime: "",
				densityFinishTime: '',
				cleanFinishTime: '',
				unblockedTestTime: '',
				remark: '',
				sort: 1
			}
			this.dataForm.pipingId = id ? id : 0;
			if (id) {
				this.getDetail();
			}
			this.$nextTick(() => {
				this.$refs['dataForm'].resetFields();
				this.visible = true;
			})
			if (id) {
				this.updateSortFlg = true
			} else {
				this.updateSortFlg = false
			}
		},
		getDetail() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/pipingprogressinfo/info/" + this.dataForm.pipingId,
				method: "get",
				params: {},
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.dataForm = data.body;
					if (this.dataForm.unuseTestFinishTime == '1') {
						this.unuseTestFinishTimeFlg = true
					} else {
						this.unuseTestFinishTimeFlg = false
					}
					if (this.dataForm.unuseDensityFinishTime == '1') {
						this.unuseDensityFinishTimeFlg = true
					} else {
						this.unuseDensityFinishTimeFlg = false
					}
					if (this.dataForm.unuseCleanFinishTime == '1') {
						this.unuseCleanFinishTimeFlg = true
					} else {
						this.unuseCleanFinishTimeFlg = false
					}
					if (this.dataForm.unuseUnblockedTestTime == '1') {
						this.unuseUnblockedTestTimeFlg = true
					} else {
						this.unuseUnblockedTestTimeFlg = false
					}
				}
			});
		},
		handleChange() {
			if (this.dataForm.subjectNo) {
				this.getMaxSort();
			}
		},
		getMaxSort() {
			let _url = "/business/pipingprogressinfo/getMaxSort";
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData({
					workNo: this.dataForm.workNo,
					subjectNo: this.dataForm.subjectNo,
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.$set(this.dataForm, 'sort', data.body)
				}
			});
		},
		dataFormSubmit() {
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					let _url = "/business/pipingprogressinfo/save";
					if (this.dataForm.pipingId && this.dataForm.pipingId !== 0) {
						_url = "/business/pipingprogressinfo/update";
					}
					if (this.unuseTestFinishTimeFlg) {
						this.dataForm.unuseTestFinishTime = '1'
					} else {
						this.dataForm.unuseTestFinishTime = '0'
					}
					if (this.unuseDensityFinishTimeFlg) {
						this.dataForm.unuseDensityFinishTime = '1'
					} else {
						this.dataForm.unuseDensityFinishTime = '0'
					}
					if (this.unuseCleanFinishTimeFlg) {
						this.dataForm.unuseCleanFinishTime = '1'
					} else {
						this.dataForm.unuseCleanFinishTime = '0'
					}
					if (this.unuseUnblockedTestTimeFlg) {
						this.dataForm.unuseUnblockedTestTime = '1'
					} else {
						this.dataForm.unuseUnblockedTestTime = '0'
					}
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData(this.dataForm)
					}).then(({
						data
					}) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.visible = false;
									this.$parent.getDataList();
								}
							});
						} else {
							this.$message.error(data.msg);
						}
					});
				}
			});
		},

	}
};
</script>

<style lang="scss" scoped="scoped">
.selItemInput.descAreaLg {
	width: 718px !important;
}

.width391 {
	width: 391px !important;
}

.addImgBox {
	width: 136px;
	height: 136px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}


.fondstyle {
	color: #3f649f;
	font-size: 15px;
}
</style>
