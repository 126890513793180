<template>
	<!-- 外购件情况 -->
	<div class="mod-config">
		<el-card body-style="padding-bottom: 0;" style="margin-top: -5px;">
			<div class="title"> 管系进度 </div>
			<div class="line"></div>
			<el-row style="margin-top: -8px;margin-left: -20px;line-height:30px;width: 103%;">
				<el-row style="margin-top: 10px;">
					<el-col :span="2">
						<div class="search_title">标的物</div>
					</el-col>
					<el-col :span="22" style="display: flex;flex-wrap: wrap;">
						<span v-for="(subject, index) in subjectList" style="margin-right: 20px;">
							<span class="search_bn_border" v-if="subjectNo === subject.displayValue"
								@click="setSubject(subject)">
								{{ subject.displayName }}
							</span>
							<span class="search_bn" v-else-if="subjectNo !== subject.displayValue"
								@click="setSubject(subject)">
								{{ subject.displayName }}
							</span>
						</span>
					</el-col>
				</el-row>
				<div class="line" style="margin-top: 10px;"></div>
				<el-row style="background-color: rgb(243, 247, 247);margin-top: -10px;height: 45px;width: 103%;">
					<el-col :span="12" style="margin-left:15px;margin-top:5px">
						<el-button v-preventReClick class="btn" type="primary" size="small" :disabled="updateFlg || addFlg"
							@click="addHandle()">添加</el-button>
						<el-button v-preventReClick class="btn" type="primary" size="small" :disabled="updateFlg || addFlg"
							@click="uploadHandle()">导入</el-button>
						<el-button v-preventReClick class="btn" type="primary" size="small" :disabled="updateFlg || addFlg"
							@click="downloadHandle()">导出</el-button>
						<el-button v-preventReClick class="btn" type="primary" size="small" :disabled="updateFlg || addFlg"
							@click="downloadTemplate()">模板下载</el-button>
						<el-button v-preventReClick :disabled="disabledBatchDelete || updateFlg || addFlg" class="btn"
							type="primary" size="small" @click="deleteBatch()">批量删除</el-button>
					</el-col>
				</el-row>
				<div class="line" style="margin-top: -2px;"></div>
				<el-form ref="form" :model="form" inline label-width="80px" label-position="left">
					<el-row style="background-color: rgb(243, 247, 247);margin-top: -10px;height: 45px;width: 103%;">
						<el-col :span="18" style="margin-left:20px">
							<!-- <el-form-item label="标的物" class="selFormItem">
							<el-select class="selSubjectInput" v-model="subjectNo" placeholder="请选择"
								:disabled="updateFlg || addFlg">
								<el-option v-for="item in $store.state.common.navBarSubjectList" :key="item.displayValue"
									:label="item.displayName" :value="item.displayValue">
								</el-option>
							</el-select>
						</el-form-item> -->
							<el-form-item label="项目名称" class="selFormItem">
								<el-input class="selSearchInput" v-model="form.pipingProjectName"
									:disabled="updateFlg || addFlg" clearable></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="4" class="selBtnGroup" style="margin-top:5px">
							<el-button v-preventReClick class="btn" type="primary" size="small"
								:disabled="updateFlg || addFlg" @click="selGetDataList()">查询</el-button>
							<el-button v-preventReClick class="rebtn" size="small" :disabled="updateFlg || addFlg"
								@click="resetFormData()">重置</el-button>
						</el-col>
					</el-row>
				</el-form>
			</el-row>
		</el-card>
		<div>
			<el-card style="margin-top: -25px; height: 500px;" body-style="height:460px; padding: 0;">
				<el-row style="height: 100%; margin-bottom: 10px;">
					<el-col :span="24" style="height: 100%;">
						<div class="box">
							<el-table height="440" :row-class-name="tableRowClassName" :row-style="selectRowBackground"
								ref="dataListTable" class="dataListTable" :data="dataList" header-align="center" border
								style="width: 100%;margin-bottom: 20px;" :cell-style="{ padding: '0px' }"
								header-cell-class-name="dataListTHeader" @selection-change="handleSelectionChange">
								<el-table-column type="selection" width="45" align="center"></el-table-column>
								<el-table-column type="index" label="NO." width="60" align="center">
									<template slot-scope="scope">{{ (pageIndex2 - 1) * pageSize + scope.$index + 1
									}}</template>
								</el-table-column>
								<!-- <el-table-column prop="subjectName" width="140" label="标的物" align="left"
									header-align="center" show-overflow-tooltip>
									<template slot-scope="scope">
										<span style="margin-left: 15px;" v-if="!getDisabled(scope.row.pipingId)">{{
											scope.row.subjectName
										}}</span>
										<el-input class="sp-input" v-if="getDisabled(scope.row.pipingId) && !addFlg"
											:disabled="true" v-model="scope.row.subjectName"></el-input>
										<el-select class="sp-input" v-model="scope.row.subjectNo" placeholder="请选择"
											v-if="getDisabled(scope.row.pipingId) && addFlg" style="width:110px">
											<el-option v-for="item in subjectList"
												:key="item.displayValue" :label="item.displayName"
												:value="item.displayValue">
											</el-option>
										</el-select>
									</template>
								</el-table-column> -->
								<el-table-column prop="pipingProjectName" label="项目名称" align="left" header-align="center"
									show-overflow-tooltip min-width="200px">
									<template slot-scope="scope">
										<span style="margin-left: 15px;" v-if="!getDisabled(scope.row.pipingId)">{{
											scope.row.pipingProjectName
										}}</span>
										<el-input class="sp-input" v-if="getDisabled(scope.row.pipingId)"
											v-model="scope.row.pipingProjectName" style="width: 90%;"></el-input>
									</template>
								</el-table-column>
								<el-table-column prop="testFinishTime" label="强度试验完成时间" width="200" align="center">
									<template slot-scope="scope">
										<span
											v-if="scope.row.unuseTestFinishTime == '1' && !getDisabled(scope.row.pipingId)">/</span>
										<span
											v-if="scope.row.unuseTestFinishTime == '0' && !getDisabled(scope.row.pipingId)">{{
												scope.row.testFinishTime }}</span>
										<el-date-picker v-model="scope.row.testFinishTime" type="date"
											value-format="yyyy-MM-dd" placeholder="选择日期"
											:disabled="scope.row.unuseTestFinishTime == '1'"
											v-if="getDisabled(scope.row.pipingId)" style="width: 135px;">
										</el-date-picker>
										<el-switch v-model="scope.row.unuseTestFinishTime"
											v-if="getDisabled(scope.row.pipingId)" active-value="1" inactive-value="0"
											style="margin-left: -7px;" active-color="#cdcdce"
											inactive-color="#7dbae2"></el-switch>
									</template>
								</el-table-column>
								<el-table-column prop="densityFinishTime" label="密性完成时间" width="200" align="center">
									<template slot-scope="scope">
										<span
											v-if="scope.row.unuseDensityFinishTime == '1' && !getDisabled(scope.row.pipingId)">/</span>
										<span
											v-if="scope.row.unuseDensityFinishTime == '0' && !getDisabled(scope.row.pipingId)">{{
												scope.row.densityFinishTime }}</span>
										<el-date-picker v-model="scope.row.densityFinishTime" type="date"
											value-format="yyyy-MM-dd" placeholder="选择日期"
											:disabled="scope.row.unuseDensityFinishTime == '1'"
											v-if="getDisabled(scope.row.pipingId)" style="width: 135px;">
										</el-date-picker>
										<el-switch v-model="scope.row.unuseDensityFinishTime"
											v-if="getDisabled(scope.row.pipingId)" active-value="1" inactive-value="0"
											style="margin-left: -7px;" active-color="#cdcdce"
											inactive-color="#7dbae2"></el-switch>
									</template>
								</el-table-column>
								<el-table-column prop="cleanFinishTime" label="串油清洁完成时间" width="200" align="center">
									<template slot-scope="scope">
										<span
											v-if="scope.row.unuseCleanFinishTime == '1' && !getDisabled(scope.row.pipingId)">/</span>
										<span
											v-if="scope.row.unuseCleanFinishTime == '0' && !getDisabled(scope.row.pipingId)">{{
												scope.row.cleanFinishTime }}</span>
										<el-date-picker v-model="scope.row.cleanFinishTime" type="date"
											value-format="yyyy-MM-dd" placeholder="选择日期"
											:disabled="scope.row.unuseCleanFinishTime == '1'"
											v-if="getDisabled(scope.row.pipingId)" style="width: 135px;">
										</el-date-picker>
										<el-switch v-model="scope.row.unuseCleanFinishTime"
											v-if="getDisabled(scope.row.pipingId)" active-value="1" inactive-value="0"
											style="margin-left: -7px;" active-color="#cdcdce"
											inactive-color="#7dbae2"></el-switch>
									</template>
								</el-table-column>
								<el-table-column prop="unblockedTestTime" label="效用试验时间" width="200" align="center">
									<template slot-scope="scope">
										<span
											v-if="scope.row.unuseUnblockedTestTime == '1' && !getDisabled(scope.row.pipingId)">/</span>
										<span
											v-if="scope.row.unuseUnblockedTestTime == '0' && !getDisabled(scope.row.pipingId)">{{
												scope.row.unblockedTestTime }}</span>
										<el-date-picker v-model="scope.row.unblockedTestTime" type="date"
											value-format="yyyy-MM-dd" placeholder="选择日期"
											:disabled="scope.row.unuseUnblockedTestTime == '1'"
											v-if="getDisabled(scope.row.pipingId)" style="width: 135px;">
										</el-date-picker>
										<el-switch v-model="scope.row.unuseUnblockedTestTime"
											v-if="getDisabled(scope.row.pipingId)" active-value="1" inactive-value="0"
											style="margin-left: -7px;" active-color="#cdcdce"
											inactive-color="#7dbae2"></el-switch>
									</template>
								</el-table-column>
								<el-table-column prop="remark" label="备注" width="220" align="left" header-align="center"
									show-overflow-tooltip>
									<template slot-scope="scope">
										<span style="margin-left: 15px;" v-if="!getDisabled(scope.row.pipingId)">{{
											scope.row.remark
										}}</span>
										<el-input class="sp-input" v-if="getDisabled(scope.row.pipingId)"
											v-model="scope.row.remark" style="width: 90%;"></el-input>
									</template>
								</el-table-column>
								<el-table-column label="操作" width="120" align="center" fixed="right">
									<template slot-scope="scope">
										<el-button v-preventReClick type="text" size="small"
											@click="updateHandle(scope.row.pipingId)"
											v-if="!updateFlg && !addFlg">修改</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="deleteHandle(scope.row.pipingId)"
											v-if="!updateFlg && !addFlg">删除</el-button>
										<!--新增时按钮-->
										<el-button v-preventReClick type="text" size="small" @click="savePipingProgress()"
											v-if="scope.row.pipingId == undefined && addFlg">保存</el-button>
										<el-button v-preventReClick type="text" size="small" @click="cancelSaveHandle()"
											v-if="scope.row.pipingId == undefined && addFlg">取消</el-button>
										<!--修改时按钮-->
										<el-button v-preventReClick type="text" size="small"
											@click="updatePipingProgress(scope.row)"
											v-if="getDisabled(scope.row.pipingId) && !addFlg">保存</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="cancelUpdateHandle(scope.row)"
											v-if="getDisabled(scope.row.pipingId) && !addFlg">取消</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<el-pagination class="my-pagination" @size-change="sizeChangeHandle"
							@current-change="currentChangeHandle" :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]"
							:page-size="pageSize" :total="totalPage" layout="total, sizes, prev, pager, next, jumper"
							:disabled="updateFlg || addFlg">
						</el-pagination>
					</el-col>
				</el-row>
			</el-card>
		</div>
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
		<excel-upload v-if="excelUploadVisible" ref="excelUpload" @refreshDataList="getDataList"></excel-upload>
	</div>
</template>
<script>
import AddOrUpdate from './pipingprogressinfo-add-or-update'
import ExcelUpload from './pipingprogressinfo-excel-upload'
import $common from "@/utils/common.js"
export default {
	data() {
		return {
			partNo: '',
			showSearch: false,
			showAdd: false,
			showDelete: false,
			showEdit: false,
			showLook: false,
			disabledBatchDelete: true,
			excelUploadVisible: false,
			updateFlg: false,
			addFlg: false,
			pageSpan: 12,
			addOrUpdateVisible: false,
			form: {
				pipingProjectName: "",
			},
			//当前页码
			pageIndex: 1,
			// 显示序号
			pageIndex2: 1,
			//当前记录数
			pageSize: 20,
			//总页数
			totalPage: 0,
			workNo: $common.getItem("workNo"),
			subjectNo: '',
			dataList: [],
			tableSelVal: [],
			recNos: [],
			subjectList: []
		};
	},
	components: {
		AddOrUpdate,
		ExcelUpload
	},
	mounted() {
		this.getSubjectDropDown()
		// this.getDataList();
	},
	methods: {
		getSubjectDropDown() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.subjectList = data.body;
					if (this.subjectList != undefined && this.subjectList.length > 0) {
						this.subjectNo = this.subjectList[0].displayValue
						this.getDataList();
					}
				}
			});
		},
		setSubject(subject) {
			if (!this.addFlg && !this.updateFlg) {
				this.subjectNo = subject.displayValue
				this.getDataList()
			}
		},
		addHandle() {
			// 首行新增一条记录
			let dataList = [];
			let mooringTest = {
				workNo: $common.getItem("workNo"),
				subjectName: '',
				subjectNo: this.subjectNo,
				testTypeId: '',
				testTypeIdName: '',
				finishDate: '',
				testItem: '',
				remark: '',
				sort: ''
			}
			dataList.push(mooringTest);
			for (var i = 0; i < this.dataList.length; i++) {
				dataList.push(this.dataList[i])
			}
			this.dataList = dataList
			this.addFlg = true;
		},
		cancelSaveHandle() {
			this.addFlg = false;
			let dataList = [];
			for (var i = 0; i < this.dataList.length; i++) {
				if (this.dataList[i].pipingId != undefined) {
					dataList.push(this.dataList[i])
				}
			}
			this.dataList = dataList
		},
		cancelUpdateHandle(val) {
			this.getDataList()
			this.recNos = [];
			this.updateFlg = false;
		},
		updateHandle(val) {
			this.recNos.push(val)
			this.updateFlg = true;
		},
		getDisabled(val) {
			if (val == '' || val == undefined) {
				return true;
			} else {
				if (this.recNos.includes(val) && this.updateFlg) {
					return true;
				}
				return false;
			}
		},
		uploadHandle() {
			this.excelUploadVisible = true;
			this.$nextTick(() => {
				this.$refs.excelUpload.init()
			})
		},
		downloadHandle() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/pipingprogressinfo/download",
				method: 'get',
				params: this.$http.adornParams({
					page: this.pageIndex,
					pipingProjectName: this.form.pipingProjectName,
					limit: this.pageSize,
					workNo: $common.getItem("workNo"),
					subjectNo: this.subjectNo,
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					if (this.$store.state.showTestFlag) {
						window.open(data.body)
					} else {
						this.global.downLoadFile(data.body, '系统管系进度跟踪表.xlsx');
					}
					// this.global.downLoadFile(data.body,'系统管系进度跟踪表.xlsx');
					// window.open(data.body)
				} else {
				}
			})
		},
		downloadTemplate() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getTemplateUrl?type=10",
				method: 'get',
				params: {}
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					if (data.body) {
						// window.open(data.body);
						if (this.$store.state.showTestFlag) {
							window.open(data.body)
						} else {
							this.global.downLoadFile(data.body, '系统管系进度跟踪表模板.xlsx');
						}
					} else {
						this.$message.error("未配置模板，请联系运维管理人员")
					}
				}
			});
		},
		// 获取数据列表
		getDataList() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/pipingprogressinfo/list",
				method: 'get',
				params: this.$http.adornParams({
					page: this.pageIndex,
					pipingProjectName: this.form.pipingProjectName,
					limit: this.pageSize,
					workNo: $common.getItem("workNo"),
					subjectNo: this.subjectNo,
				})
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.dataList = data.body.records;
					this.totalPage = data.body.totalCount;
					this.pageIndex2 = this.pageIndex
				} else {
					this.dataList = []
					this.totalPage = 0
					this.pageIndex2 = 1
				}
			})
		},
		savePipingProgress() {
			if (this.dataList[0].subjectNo == null || this.dataList[0].subjectNo == "") {
				this.$message.error("请选择标的物");
				return;
			}

			if (this.dataList[0].pipingProjectName == null || this.dataList[0].pipingProjectName == "") {
				this.$message.error("请填写项目名称");
				return;
			}

			let _url = "/business/pipingprogressinfo/getMaxSort";
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData({
					workNo: this.dataList[0].workNo,
					subjectNo: this.dataList[0].subjectNo,
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataList[0].sort = data.body
					_url = "/business/pipingprogressinfo/save";
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData(this.dataList[0])
					}).then(({
						data
					}) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
							});
							this.getDataList()
							this.addFlg = false
						}
					});
				}
			});
		},
		updatePipingProgress(val) {
			if (val.pipingProjectName == null || val.pipingProjectName == "") {
				this.$message.error("请填写项目名称");
				return;
			}
			let _url = "/business/pipingprogressinfo/update";
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData(val)
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
					});
					this.recNos = []
					this.getDataList()
					this.updateFlg = false
				}
			});
		},
		handleSelectionChange(selection) {
			this.tableSelVal = selection;
			if (selection.length > 0) {
				this.disabledBatchDelete = false;
			} else {
				this.disabledBatchDelete = true;
			}
		},
		deleteBatch() {
			this.$confirm(`确定对选中的数据进行删除操作？`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let records = this.tableSelVal.map((item) => item.pipingId);
				this.$http({
					url: this.$store.state.httpUrl + "/business/pipingprogressinfo/deleteBatch",
					method: 'post',
					data: this.$http.adornData({ records })
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				})
			});
		},
		siteContentViewHeight() {
			var height = this.$store.state.common.documentClientHeight - 260;
			return {
				height: height + 'px'
			}
		},
		addOrUpdateHandle(id) {
			this.addOrUpdateVisible = true;
			this.$nextTick(() => {
				this.$refs.addOrUpdate.init(id)
			})
		}, showLookHandle(id) {
			this.showLook = true
			this.$nextTick(() => {
				this.$refs.meetingLook.init(id)
			})
		},
		deleteHandle(id) {
			this.$confirm(`确定对选中的数据进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/pipingprogressinfo/delete/" + id,
					method: 'post'
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				})
			});
		},
		resetFormData() {
			// this.subjectNo = ''
			this.form.pipingProjectName = "";
			this.pageIndex = 1;
			this.recNos = []
			this.getDataList();
			this.updateFlg = false
		},
		selGetDataList() {
			this.pageIndex = 1;
			this.getDataList();
			this.recNos = []
			this.updateFlg = false
		},
		// 每页数
		sizeChangeHandle(val) {
			this.pageSize = val;
			this.pageIndex = 1;
			this.getDataList();
		},
		// 当前页
		currentChangeHandle(val) {
			this.pageIndex = val;
			this.getDataList();
		},
		tableRowClassName({ row, rowIndex }) {
			return $common.tableRowClassName({ row, rowIndex });
		},
		selectRowBackground({ row }) {
			const checkIdList = this.tableSelVal.map((item) => item.pipingId);
			if (checkIdList.includes(row.pipingId)) {
				return {
					backgroundColor: "#fae8cb",
				};
			}
		},
	}
};
</script>
<style scoped="scoped">
.selItemText {
	font-size: 14px;
	color: #1c1c1c;
	display: inline-block;
	width: 80px;
}

.sp-input /deep/ .el-input__inner {
	width: 100% !important;
}

.my-pagination {
	margin-right: 20px !important;
}

::v-deep(.el-table th.el-table__cell) {
	/* background-color: #d3e2f4 !important; */
	font-size: 15px;
	padding: 2px 0;
}

::v-deep(.selSubjectInput .el-input__inner) {
	width: 120px !important;
	height: 30px !important;
}

::v-deep(.selSearchInput .el-input__inner) {
	width: 150px !important;
	height: 30px !important;
}

::v-deep(.selFormItem .el-form-item__label) {
	font-size: smaller;
}
</style>
